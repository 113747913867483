@use "../mixin/mixin" as *;
@use "../setting/setting" as *;

.wp-pagenavi {
  display: flex;
  gap: 17px;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  margin-top: 60px;
  border-top: 1px solid $border;

  @include sp {
    margin-top: 32px;
    gap: 10px;

    .news & {
      order: 4;
    }
  }

  a,
  span {
    font: normal 14px/1.5 $font-ja;
    letter-spacing: 1.4px;
    white-space: nowrap;

    &.current {
      color: $primary;
    }

    &._last,
    &._first,
    &.nextpostslink,
    &.previouspostslink {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        content: "";
        background-color: $gray;
        mask: url("../img/icon_arrow.svg") no-repeat center center / contain;
        transform: translate(-50%, -50%);
      }
    }

    &._first,
    &.previouspostslink {
      &::after {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }
}
