@charset "UTF-8";
.subMenu._debug {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}
.subMenu[data-menu-size=large] {
  width: 100dvw;
  left: inherit;
  right: -32px;
  padding-left: 32px;
  padding-right: 32px;
}
@media only screen and (min-width: 1025px) and (max-width: 1245px) {
  .subMenu[data-menu-size=large] {
    right: -12px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.subMenu__body {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 42px;
}
@media only screen and (min-width: 1025px) and (max-width: 1245px) {
  .subMenu__body {
    gap: 28px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1060px) {
  .subMenu__body {
    gap: 18px;
  }
}
.subMenu__categoryName {
  font-weight: bold;
  color: #fff;
  transition: none;
}
@media only screen and (min-width: 1025px) and (max-width: 1245px) {
  .subMenu__categoryName {
    font-size: 14px;
  }
}
.subMenu__categoryName:has(+ .subMenu__item) {
  margin-bottom: 4px;
}
.subMenu__categoryName:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 4px;
  padding-top: 20px;
}
@media only screen and (min-width: 1025px) and (max-width: 1245px) {
  .subMenu__categoryName:not(:first-child) {
    margin-top: 4px;
    padding-top: 14px;
  }
}
.subMenu__categoryName a {
  position: relative;
  display: block;
  color: #fff;
}
@media only screen and (min-width: 1025px) {
  .subMenu__categoryName a {
    transition: opacity 0.3s;
  }
}
.subMenu__categoryName a::after {
  position: absolute;
  right: 0;
  width: 16px;
  height: 1.4em;
  top: inherit;
  content: "";
  background-color: #e3e3e3;
  mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
  transition: all 0.3s;
}
@media only screen and (min-width: 1025px) {
  .subMenu__categoryName a:hover {
    opacity: 0.5;
  }
}
.header .subMenu__inner:not(:has([class*=categoryName])) {
  gap: 16px;
}
.header .subMenu__inner:has([class*=categoryName]) {
  gap: 14px;
}
@media only screen and (min-width: 1025px) and (max-width: 1245px) {
  .header .subMenu__inner {
    gap: 12px;
  }
}
[data-menu-size=large] .subMenu__inner {
  align-items: start;
  max-width: 320px;
}
.header .headerLink__item .subMenu__itemLink {
  font: normal 16px/1.4 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
}
@media only screen and (min-width: 1025px) and (max-width: 1245px) {
  .header .headerLink__item .subMenu__itemLink {
    font-size: 13px;
  }
}
[data-menu-size=large] .subMenu__itemLink {
  display: flex;
  flex-wrap: wrap;
  padding-right: 26px;
}
[data-menu-size=large] .subMenu__itemLink::after {
  height: 1.4em;
  top: inherit;
  transform: none;
}

.headerLink {
  gap: 22px;
}
@media only screen and (min-width: 1025px) {
  .headerLink {
    gap: 20px;
    letter-spacing: 0.04em;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1245px) {
  .headerLink {
    gap: 14px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1060px) {
  .headerLink {
    gap: 10px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1245px) {
  .headerLink__itemLink {
    text-align: center;
    letter-spacing: 0.03em;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1060px) {
  .headerLink__itemLink {
    letter-spacing: 0em;
  }
}
.headerLink__itemLink br {
  display: none;
}

@media only screen and (min-width: 1025px) and (max-width: 1245px) {
  .header__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1060px) {
  .header__inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.js-accordionList__item__inner + .js-accordionList__item__inner {
  border-top: 1px solid #e3e3e3;
}
.js-accordionList__item__inner:not(:last-child) .js-accordionList__item__content {
  padding-bottom: 0;
}

.js-accordionList__item__catName {
  display: block;
  background: #f7f7f7;
  color: rgba(34, 34, 34, 0.8);
}
.js-accordionList__item__catName:not(:last-child) {
  border-bottom: 1px solid #e3e3e3;
}
.js-accordionList__item__catName:not(:has(a)) {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;
}
.js-accordionList__item__catName a {
  padding-top: 14px;
  padding-bottom: 14px;
}

.drawerWrap .drawerButton {
  position: absolute;
}

.wp-pagenavi {
  display: flex;
  gap: 17px;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  margin-top: 60px;
  border-top: 1px solid #e3e3e3;
}
@media only screen and (max-width: 1024px) {
  .wp-pagenavi {
    margin-top: 32px;
    gap: 10px;
  }
  .news .wp-pagenavi {
    order: 4;
  }
}
.wp-pagenavi a,
.wp-pagenavi span {
  font: normal 14px/1.5 "Helvetica Neue", arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  letter-spacing: 1.4px;
  white-space: nowrap;
}
.wp-pagenavi a.current,
.wp-pagenavi span.current {
  color: #00aec4;
}
.wp-pagenavi a._last, .wp-pagenavi a._first, .wp-pagenavi a.nextpostslink, .wp-pagenavi a.previouspostslink,
.wp-pagenavi span._last,
.wp-pagenavi span._first,
.wp-pagenavi span.nextpostslink,
.wp-pagenavi span.previouspostslink {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}
.wp-pagenavi a._last::after, .wp-pagenavi a._first::after, .wp-pagenavi a.nextpostslink::after, .wp-pagenavi a.previouspostslink::after,
.wp-pagenavi span._last::after,
.wp-pagenavi span._first::after,
.wp-pagenavi span.nextpostslink::after,
.wp-pagenavi span.previouspostslink::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #888;
  mask: url("../img/icon_arrow.svg") no-repeat center center/contain;
  transform: translate(-50%, -50%);
}
.wp-pagenavi a._first::after, .wp-pagenavi a.previouspostslink::after,
.wp-pagenavi span._first::after,
.wp-pagenavi span.previouspostslink::after {
  transform: translate(-50%, -50%) rotate(180deg);
}

/* -----------------------------------------------
* 追加・改修スタイル 2024-01-26~
-------------------------------------------------- */