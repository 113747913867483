@use "../mixin/mixin" as *;
@use "../setting/setting" as *;


.subMenu {
  &._debug {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
  }
  &[data-menu-size="large"] {
    width: 100dvw;
    left: inherit;
    right: -32px;
    padding-left: 32px;
    padding-right: 32px;
    @include pc- {
      right: -12px;
      padding-left: 12px;
      padding-right: 12px;
      // padding-right: 0;
      // width: 100%;
    }
  }

  &__body {
    display: flex;
    align-items: start;
    // flex-wrap: wrap;
    // justify-content: flex-end;
    justify-content: center;
    gap: 42px;
    @include pc- {
      gap: 28px;
    }
    @include pc-s {
      gap: 18px;
    }
  }

  &__categoryName {
    font-weight: bold;
    color: #fff;
    transition: none;
    @include pc- {
      font-size: 14px;
    }
    &:has( + .subMenu__item) {
      margin-bottom: 4px;
    }
    &:not(:first-child) {
      border-top: 1px solid rgba($color: #fff, $alpha: 0.2);
      margin-top: 4px;
      padding-top: 20px;
      @include pc- {
        margin-top: 4px;
        padding-top: 14px;
      }
    }
    a {
      position: relative;
      display: block;
      color: #fff;
      @include pc {
        transition: opacity .3s;
      }
      &::after {
        position: absolute;
        right: 0;
        width: 16px;
        height: calc( 1em * 1.4 );
        top: inherit;
        content: "";
        background-color: #e3e3e3;
        mask: url("../img/icon_arrow.svg") no-repeat center center / contain;
        transition: all 0.3s;
      }
      &:hover {
        @include pc {
          opacity: .5;
        }
      }
    }
  }

  &__inner {
    .header & {
      &:not(:has([class*="categoryName"])) {
        gap: 16px;
      }
      &:has([class*="categoryName"]) {
        gap: 14px;
      }
      @include pc- {
        gap: 12px;
      }
    }
    [data-menu-size="large"] & {
      align-items: start;
      max-width: 320px;
    }
  }

  &__item {
    &Link {
      .header .headerLink__item & {
        font: normal 16px / 1.4 $font-ja;
        @include pc- {
          font-size: 13px;
        }
      }
      [data-menu-size="large"] & {
        display: flex;
        flex-wrap: wrap;
        padding-right: 26px;
        // align-items: baseline;
        &::after {
          height: calc( 1em * 1.4 );
          top: inherit;
          transform: none;
        }
      }
    }
  }
}

.headerLink {
  gap: 22px;
  @include pc {
    gap: 20px;
    letter-spacing: 0.04em;
  }
  @include pc- {
    gap: 14px;
  }
  @include pc-s {
    gap: 10px;
  }
  &__itemLink {
    @include pc- {
      text-align: center;
      letter-spacing: 0.03em;
    }
    @include pc-s {
      letter-spacing: 0em;
    }
    br {
      display: none;
      // @include pc- {
      //   display: block;
      // }
    }
  }
}
.header__inner {
  @include pc- {
    padding-left: 12px;
    padding-right: 12px;
  }
  @include pc-s {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.js-accordionList__item__inner {
  & + & {
    border-top: 1px solid #e3e3e3;
  }
  &:not(:last-child) {
    .js-accordionList__item__content {
      padding-bottom: 0;
    }
  }
}
.js-accordionList__item__catName {
  display: block;
  background: #f7f7f7;
  color: rgba($color: #222, $alpha: .8);
  &:not(:last-child) {
    border-bottom: 1px solid #e3e3e3;
  }
  &:not(:has(a)) {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
  }
  a {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.drawerWrap .drawerButton {
  position: absolute;
}
